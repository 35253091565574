<template>
  <v-card
    height="200px"
    class="mx-16 my-8 d-flex flex-column justify-space-between"
    style=" border: 2px dashed #2acccc;
            border-radius: 16px;"
  >
    <section class="pa-6">
      <v-row>
        <v-col cols="10">
          <v-row>
            <v-col>
              <span class="mr-2 font-weight-bold h3" v-text="player.firstName" /><span class="font-weight-light h3" v-text="player.lastName" />
            </v-col>
          </v-row>
          <v-row v-if="enabledSelectTeams">
            <v-col cols="5" class="custom d-flex align-end">
              <v-select
                :label="playerTeamId ? teams.find(team => team.id === playerTeamId).fullTeamName : $t('project.academy.users.assignTeam')"
                :items="teams"
                item-text="fullTeamName"
                item-value="id"
                dense
                append-icon="mdi-chevron-down"
                hide-details="auto"
                @change="newTeamId => $emit('updatePlayerTeam', { oldTeamId: playerTeamId, newTeamId: newTeamId })"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2">
          <v-avatar size="64" style=" border: 2px solid #2acccc;">
            <img v-if="player.avatar" alt="Avatar" :src="player.avatar">
            <v-icon v-else color="primary" v-text="'person'" />
          </v-avatar>
        </v-col>
      </v-row>
    </section>
    <v-card-text style="background-color: #e1ffff;">
      <v-row class="d-flex pl-2">
        <v-col :cols="$vuetify.breakpoint.smAndDown ? '3' : '2'" class="d-flex flex-row">
          <v-icon color="primary" small>
            cake
          </v-icon>
          <span class="ml-2" v-text="helpers.formatBirthdate(player)" />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? '3' : '2'" class="d-flex flex-row">
          <v-icon color="primary" small>
            outlined_flag
          </v-icon>
          <span class="ml-2" v-text="helpers.formatCountry(player)" />
        </v-col>
        <v-col class="d-flex flex-row">
          <v-icon color="primary" small>
            email
          </v-icon>
          <span class="ml-2" v-text="player.email.slice(- 10) !== '@owqlo.biz' ? player.email : '-'" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PlayerCard',
  props: {
    organizationId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    player: {
      type: Object,
      required: true,
    },
    teams: {
      type: Array,
      default: () => [],
    },
    playerTeamId: {
      type: String,
      default: null,
    },
    helpers: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({ isAcademy: 'project/isAcademy' }),
    enabledSelectTeams: ({ isAcademy }) => isAcademy,
  },
}
</script>

<style scoped>
.v-avatar img {
  width: 100%;
  object-fit: cover;
}
</style>
